.ContactsContainer {
    width: 20%;
    height: fit-content;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: #FFF;
    min-width: 180px;
}

.ContactsContainer .phoneButton {
    color: #08C;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    background-color: transparent;
    padding: 0;
    height: auto;
    margin-left: 8px;
    cursor: pointer;
}

.ContactsContainer .whatsapp {
    display: flex;
    height: 48px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #25D366;
    color: #25D366;
    margin-bottom: 8px;
    margin-top: 16px;
}

.ContactsContainer .telegram {
    display: flex;
    height: 48px;
    margin-top: 8px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #08C;
    color: #08C;
}

@media only screen and (max-width: 480px) {
    .ContactsContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        background: #FFF;
        min-width: 180px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

}