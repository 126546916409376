.FooterSection {
    display: flex;
    padding: 50px;
    justify-content: center;
    align-items: center;
}

.FooterContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FooterContent .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.FooterContent .supportInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}

.Address {
    color: #1E1E1E;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Copyright {
    color: #7D7D7D;
    font-family: Helvetica Neue, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.TechSupport {
    color: #7D7D7D;
    text-align: right;
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Email {
    color: #1E1E1E;
    text-align: right;
    font-family: Helvetica Neue, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 480px) {
    .FooterSection {
        padding: 20px;
    }

    .FooterContent {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
    }

    .FooterContent .supportInfo {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 10px;
    }

}