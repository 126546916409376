.heroSection {
    width: 100%;
    height: 699px;
    margin-top: 70px;
    padding: 50px;
}

.top {
    display: flex;
    justify-content: space-between;
}

.heroSection h1 {
    color: #C8232C;
    font-family: Helvetica Neue, serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}

.mainTitle {
    color: #C8232C;
    font-family: Helvetica Neue, serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}

.heroSection h2 {
    color: #7D7D7D;
    font-family: Helvetica Neue, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.middle {
    width: 100%;
    height: 428px;
    margin-top: 50px;
    background-image: url('./background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 60px;
    position: relative;
}

.corners {

}

.ButtonContainer {
    width: 270px;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F7F7F7;
    border-radius: 0 0 20px 0;
    padding: 0 30px 20px 0;
}

.ButtonContainer button {
    transition: all 0.3s ease-out;
    outline: none;
    cursor: pointer;
}

.buttonContainerRightCorner {
    position: absolute;
    top: 0;
    left: 270px;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.buttonContainerLeftCorner {
    position: absolute;
    top: 80px;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.middleRightTopContainer {
    position: absolute;
    bottom: 80px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.bottomLeftCorner {
    position: absolute;
    bottom: 0;
    right: 270px;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.middleRight {
    width: 270px;
    height: 80px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #F7F7F7;
    border-radius: 20px 0 0 0;
    padding: 20px;
}

.middleRight span {
    color: #1E1E1E;
    font-family: Helvetica Neue, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;;
}

.bottom {
    margin-top: 50px;
    width: 100%;
}

.bottom h3 {
    color: #7D7D7D;
    font-family: Helvetica Neue, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

button {
    display: inline-flex;
    height: 52px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border: none;
    background: #C8232C;
    color: white;
    cursor: pointer;
}


#curvedCornerBottomleft,
#curvedCornerBottomright,
#curvedCornerTopleft,
#curvedCornerTopright {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
}

#curvedCornerBottomleft:before,
#curvedCornerBottomright:before,
#curvedCornerTopleft:before,
#curvedCornerTopright:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
}

#curvedCornerBottomleft:before {
    bottom: 0;
    left: 0;
    box-shadow: -15px 15px 0 0 #F7F7F7;
}

#curvedCornerBottomright:before {
    bottom: 0;
    right: 0;
    box-shadow: 15px 15px 0 0 #F7F7F7;
}

#curvedCornerTopleft:before {
    top: 0;
    left: 0;
    box-shadow: -15px -15px 0 0 #F7F7F7;
}

#curvedCornerTopright:before {
    top: 0;
    right: 0;
    box-shadow: 15px -15px 0 0 #F7F7F7;
}

.topMiddle {

}

@media only screen and (max-width: 480px) {
    .heroSection {
        width: 100%;
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .topMiddle {
        height: 558px;
        width: 100%;
        background-image: linear-gradient(to top, rgba(247, 247, 247, 0.7), rgba(247, 247, 247, 0)), url('./background.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .heroSection h2 {
        color: #7D7D7D;
        font-family: Helvetica Neue, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 16px;
    }

    .ButtonContainer {
        width: 100%;
        height: auto;
        border-radius: 0;
        padding: 0 16px 0 16px;
        background-color: transparent;
    }

    .ButtonContainer button {
        width: 100%;
        transition: all 0.3s ease-out;
        outline: none;
        cursor: pointer;
        font-size: 16px;
    }

    .corners {
        display: none;
    }

    .middle {
        width: 100%;
        height: auto;
        border-radius: 0;
        position: absolute;
        bottom: 60px;
        margin-top: 0;
    }

    .top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 32px;
    }

    .mainTitle {
        color: #C8232C;
        font-family: Helvetica Neue, serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
        text-align: left;
    }

    .bottom h3 {
        color: #7D7D7D;
        font-family: Helvetica Neue, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-top: 0;
        padding: 0;
    }

    .bottom {
        margin-top: 0;
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}