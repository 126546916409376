* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
}

a {
    text-decoration: none;
}