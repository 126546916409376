.BioContainer {
    width: 50%;
    padding-right: 50px;
}

.NameContainer {

}

.AvatarContainerForMobile {
    display: none;
}

.BioContainer .description {
    margin-top: 24px;
    margin-bottom: 50px;
}

.StatisticsContainer {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 32px;
}

.StatisticsContainer div {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    text-align: center;
}

.StatisticsContainer .statNumber {
    color: #C8232C;
    font-size: 40px;
    font-weight: 400;
    line-height: 140%;
}

.StatisticsContainer .statDescription {
    color: #1E1E1E;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 480px) {
    .BioContainer {
        width: 100%;
        padding: 0;
    }

    .NameContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .AvatarContainerForMobile {
        display: block;
    }

    .AvatarContainerForMobile img {
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .StatisticsContainer {
        width: 100%;
        display: flex;
        align-items: stretch;
        gap: 8px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .StatisticsContainer .statNumber {
        color: #C8232C;
        font-size: 20px;
        font-weight: 400;
        line-height: 140%;
    }

    .StatisticsContainer .statDescription {
        color: #1E1E1E;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
}