.ServicesSection {
    padding-top: 50px;
    padding-bottom: 25px;
}

h2 {
    color: #7D7D7D;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
}

.ServicesContainer {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 32px;
    margin-top: 50px;
    position: relative;
}

.ServicesContainer .serviceBox {
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    text-align: start;
}

.ServicesContainer ul {
    margin-bottom: 24px;
}

.ServicesContainer h3 {
    color: #C8232C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
    margin-top: 0;
}

.ServicesContainer .priceContainer {
    position: absolute;
    bottom: 24px;
}

.ServicesContainer .price {
    color: #1E1E1E;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
}

.ServicesContainer ul {
    list-style: none;
    row-gap: 9px;
    margin-bottom: 24px;
}

.ServicesContainer li {
    color: #1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 480px) {
    .ServicesSection {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .ServicesContainer .priceContainer {
        position: relative;
        bottom: 0;
    }

    .ServicesContainer {
        width: 100%;
        display: flex;
        align-items: stretch;
        gap: 32px;
        margin-top: 24px;
        position: relative;
        flex-direction: column;
    }

    .ServicesContainer .serviceBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        border-radius: 16px;
        background: #FFF;
        padding: 24px;
        text-align: start;
    }
}
