.mainHeader {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 70px;
    padding: 0 30px;
    transition: all 200ms linear;
    width: 100%;
    z-index: 50;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}

.logoHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    float: left;
    position: relative;
}

.logoHolder img {
    width: auto;
}

.navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    float: right;
    position: relative;
    text-decoration: none;
}

.navLink span {
    color: #C8232C;
    text-align: right;
    font-family: Helvetica Neue, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.DropDownProfile {
    position: absolute;
    top: 4.5rem;
    right: 0;
    width: 180px;
    background: #fff;
    padding: 12px 0;
    border-radius: 8px;
    border: thin solid #7D7D7D1A;
}

.DropDownProfile ul {
    list-style: none;
    cursor: pointer;
}

.DropDownProfile li {
    padding: 12px 12px;
    cursor: pointer;
}

.DropDownProfile li:hover {
    background-color: #f0f0f0; /* Replace with your preferred color */
    cursor: pointer;
}

.DropDownProfile h3 {
    font-size: 16px;
    font-weight: 400;
    color: #7D7D7D;
    padding: 12px 12px;
    margin: 0;
}

.userAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.loginButton {
    margin-left: 12px;
    cursor: pointer;
    border: 1px solid #C8232C;
    background: #C8232C;
    border-radius: 8px;
    padding: 8px 12px;
}

.loginButton span {
    color: #fff;
    text-align: center;
    font-family: Helvetica Neue, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}