.MainContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
}

.Header {
    display: flex;
    align-items: center;
    gap: 16px;
}

.MainContainer h1 {
    color: #C8232C;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-right: 16px;
}

.MainContainer h2 {
    color: #7D7D7D;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}

.Header .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.RoomTypes {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin-top: 32px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex: 0 0 auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.RoomTypes::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit-based browsers */
}

.RoomType {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 16px 30px;
    border: 1px solid #7D7D7D;
    cursor: pointer;
}

.RoomType h3 {
    color: #7D7D7D;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

.active {
    border: 1px solid #C8232C;

    h3 {
        color: #C8232C;
    }
}

.inactive {
    border: 1px solid #7D7D7D;

    h3 {
        color: #7D7D7D;
    }
}

.MobileVersionImages {
    display: none;
}

.WorkImage {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;
    max-width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.WorkImage .mainImage {
    height: 100%;
    max-width: 100%;
    display: block; /* En}sure the image behaves as a block element */
    margin-left: 24px;
    margin-right: 24px;
    overflow: hidden;
    user-select: none;
}

.CounterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    color: #1E1E1E;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    user-select: none;
}

@media only screen and (max-width: 480px) {
    .MainContainer h1 {
        color: #C8232C;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        margin-right: 16px;
    }

    .MainContainer h2 {
        color: #7D7D7D;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }

    .WorkImage {
        display: none;
    }

    .CounterContainer {
        display: none;
    }

    .MobileVersionImages {
        margin-top: 12px;
        width: 100%;
        overflow-y: scroll;
        display: block;
        scrollbar-width: none; /* Hide vertical scrollbar for Firefox */
    }

    .MobileVersionImages::-webkit-scrollbar {
        display: none; /* Hide vertical scrollbar for WebKit-based browsers */
    }

    .MobileVersionImages img {
        height: auto;
        width: 100%;
        display: block;
        object-fit: cover;
        min-width: 100%;
        margin-top: 8px;
    }

}
