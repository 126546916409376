.worksTable {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 10px;
}

.workRow {
    display: block;
    background-color: #7D7D7D;
    height: 150px;
    position: relative;
    width: fit-content;
    padding: 0;
    margin: 0;
    border: 1px solid #7D7D7D;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.workRow img {
    width: auto;
    height: 100%;
    object-fit: contain;
    margin: 0;
    padding: 0;
    display: block;
}

@media only screen and (max-width: 1600px) {
    .worksTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .workRow {
        height: 150px;
    }
}

@media only screen and (max-width: 1400px) {
    .worksTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .workRow {
        height: 120px;
    }
}

@media only screen and (max-width: 1200px) {
    .worksTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .workRow {
        height: 100px;
    }
}

.projectTitle {
    position: absolute;
    bottom: 0px;
    font-size: 14px;
    margin: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background to make the text more readable */
    padding: 5px;
    z-index: 1;
    width: 100%;
}

.addButton {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    margin-bottom: 12px;
    cursor: pointer;
}

.deleteButton {
    background-color: #C8232C;
    color: white;
    margin-bottom: 12px;
    margin-right: 12px;
    cursor: pointer;
}

.noProjects {
    color: #7D7D7D;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.addProjectModalContainer {

}

.addServiceBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.addServiceBlock button {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    cursor: pointer;
}

select {
    width: 100%;
    padding: 16px 14px 16px 14px;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #7D7D7D;
    background: white;
    color: #1E1E1E;
}

.roomTypeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.formControl {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.formControl label {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 600;
    cursor: default;
    margin-bottom: 6px;
}

.formControl {
    margin-top: 6px;
}

.error {
    color: red;
    font-size: 12px;
    text-align: left;
    padding-top: 4px;
}

.servicesList {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
    align-self: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex: 0 0 auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    margin-bottom: 10px;
}

.servicesList::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit-based browsers */
}

.service {
    display: flex;
    height: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    flex-wrap: nowrap;
    padding: 16px;
    border: 1px solid #7D7D7D;
}

.service h3 {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

.deleteServiceButton {
    height: 24px;
    width: 24px;
    background: transparent url('../icons/delete-button.svg') no-repeat center;
    background-size: contain; /* Ensures the image covers the button */
    padding: 0;
    margin-left: 4px;
    border: none; /* Remove default button border */
    cursor: pointer; /* Change cursor to pointer on hover */
    outline: none; /* Remove focus outline */
    transition: transform 0.2s; /* Add a hover effect */
}
