.profileInfoHeader {
    display: block;
    margin-top: 16px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
    color: #1E1E1E;
}

.profileInfoHeader img {
    display: block;
    margin: 0 auto 20px;
}

.error {
    color: red;
    font-size: 12px;
    text-align: left;
    padding-top: 4px;
}

.formControl {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.formControl label {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 600;
    cursor: default;
    margin-bottom: 6px;
}

.formControl {
    margin-top: 6px;
}

.userAvatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.cityName {
    font-size: 14px;
    font-weight: 400;
    color: #C8232C;
}

.profileInfoCenter .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.profileInfoCenter .title {
    font-size: 14px;
    font-weight: 400;
    color: #7D7D7D;
}

.profileInfoCenter .value {
    font-size: 16px;
    font-weight: 550;
    color: #1E1E1E;
}

.tooltipWrapper {
    position: relative;
    display: inline-block;
    margin-left: 8px; /* Space between the word and the icon */
    cursor: pointer;
}

.questionIcon {
    background-color: #7D7D7D;
    color: #fff;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
}

.tooltip {
    visibility: hidden;
    opacity: 0;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    bottom: 120%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    transition: opacity 0.2s;
}

.tooltipWrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.divider {
    margin: 20px 0;
    border: 1px solid #7D7D7D;
    opacity: 0.1;
}

.profileDescriptionContainer {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profileDescription {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
}

.profileDescription.expanded {
    -webkit-line-clamp: initial; /* remove the clamping */
}

.profileDescriptionContainer button {
    margin-top: 10px;
    color: #7D7D7D;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.editButtonContainer {
    display: flex;
    justify-content: center;
}

.editButtonContainer button {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    cursor: pointer;
}

.addButton {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    margin-bottom: 12px;
    cursor: pointer;
}