.mainContainer {
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
}

h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #1E1E1E;
    margin-bottom: 20px;
}

.divider {
    border: 1px solid #7D7D7D1A;
    margin-top: 12px;
    margin-bottom: 12px;
}

.addButton {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    margin-bottom: 12px;
    cursor: pointer;
}

.roomsContainer {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 24px;
    align-items: center;
    flex-grow: 1;
}

.rightContainers {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 432px;
}

.rightContainer {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 24px;
    align-items: center;
    flex-shrink: 0;
}

.rightContainer img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.uploadImageButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    border: 1px dashed #7D7D7D;
    border-radius: 8px;
}

.uploadImageButton button {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    height: 32px;
    font-size: 14px;
}

.containerHeader {
    height: 34px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
}

.projectInfo div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
}

.projectInfoKey {
    font-size: 16px;
    font-weight: 700;
    color: #7D7D7D;
}

.noRooms {
    color: #7D7D7D;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.RoomTypes {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex: 0 0 auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.RoomTypes::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit-based browsers */
}

.RoomType {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 16px 30px;
    border: 1px solid #7D7D7D;
    cursor: pointer;
}

.RoomType h3 {
    color: #7D7D7D;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

.editButtonContainer {
    display: flex;
    justify-content: center;
}

.editButtonContainer button {
    background-color: #7D7D7D1A;
    color: #1E1E1E;
    cursor: pointer;
}

.active {
    border: 1px solid #C8232C;

    h3 {
        color: #C8232C;
    }
}

.inactive {
    border: 1px solid #7D7D7D;

    h3 {
        color: #7D7D7D;
    }
}

.roomImagesTable {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.deleteButton {
    height: 24px;
    width: 24px;
    background: transparent url('../icons/delete-button.svg') no-repeat center;
    background-size: contain; /* Ensures the image covers the button */
    padding: 0;
    border: none; /* Remove default button border */
    cursor: pointer; /* Change cursor to pointer on hover */
    outline: none; /* Remove focus outline */
    transition: transform 0.2s; /* Add a hover effect */
    position: absolute;
    top: 10px;
    right: 10px;
}

.deleteButton:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
}

.coverImageContainer {
    position: relative;
}

.roomImage {
    display: block;
    height: 200px;
    width: 266px;
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.roomImage img {
    width: auto;
    height: 100%;
    object-fit: contain;
    margin: 0;
    padding: 0;
    display: block;
}

input[type="file"] {
    display: none;
}

label {
    cursor: pointer;
}

@media only screen and (max-width: 1600px) {
    .roomImagesTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .roomImage {
        height: 200px;
        width: 260px;
    }
}

@media only screen and (max-width: 1400px) {
    .roomImagesTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .roomImage {
        height: 150px;
        width: 200px;
    }
}

@media only screen and (max-width: 1200px) {
    .roomImagesTable {
        grid-template-columns: repeat(3, 1fr); /* 4 equal columns */
    }

    .roomImage {
        height: 120px;
        width: 160px;
    }
}

