.mainContainer {
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
}

.bannerSection {
    width: 100%;
}

h1 {
    color: #C8232C;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

p {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}

.bannerHeader {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;
}

.BackButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease-out;
    outline: none;
    cursor: pointer;
    color: #7D7D7D;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.ViewsCount {
    display: flex;
    align-items: flex-end;
    gap: 8px;
}

.ViewsCount span {
    color: #7D7D7D;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.InfoSection {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
}

.AvatarContainer {
    width: 30%;
    display: flex;
    justify-content: right;
    padding-left: 20px;
    height: fit-content;
}

.AvatarContainer img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 24px;
}

@media only screen and (max-width: 480px) {
    .mainContainer {
        margin-top: 70px;
        margin-left: 16px;
        margin-right: 16px;
    }

    .InfoSection {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
    }

    .AvatarContainer {
        display: none;
    }
}