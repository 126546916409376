.mainContainer {
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
}

.divider {
    margin: 20px 0;
    border: 1px solid #7D7D7D;
    opacity: 0.1;
}

.container2Header {
    height: 34px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.container2Header div {
    padding: 4px 8px 12px 8px;
    cursor: pointer;
}

.selected {
    color: #C8232C;
    border-bottom: 2px solid #C8232C;
}

.container2Divider {
    margin-bottom: 12px;
    border: 1px solid #7D7D7D;
    opacity: 0.1;
}

h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #1E1E1E;
    margin-bottom: 20px;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.container1 {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 24px;
    width: 432px;
    align-items: center;
    flex-shrink: 0;
}

.container2 {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 24px;
    flex-grow: 1;
}
