.mainContainer {
    margin-top: 80px;
    margin-left: 50px;
    margin-right: 50px;
}

.aboutUsHeader {
    padding: 50px 20px;
    margin-bottom: 30px;
}

.aboutUsHeader h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.aboutUsHeader p {
    font-size: 18px;
    color: #666;
}

.aboutUsSection {
    margin-bottom: 30px;
}

.aboutUsSection h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.aboutUsSection p,
.aboutUsSection ul {
    font-size: 16px;
}

.aboutUsSection ul {
    list-style: none;
    padding: 0;
}

.aboutUsSection ul li {
    margin: 5px 0;
}

