.loginContainer {
    /* Centering the container */
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;

    max-width: 588px; /* Adjust this value as needed */

    /* Styling the container */
    background-color: white;
    border-radius: 24px;
    padding: 36px 87px 36px 87px;
    /* Ensure it is a block-level element */

    /* Centering the content horizontally */
    text-align: center; /* Optional: Center text if there is text content */
}

@media only screen and (max-width: 480px) {
    .loginContainer {
        margin-top: 100px;
        padding: 36px 24px 36px 24px;
    }
}

.loginInput {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #7D7D7D;
}

.title {
    color: #1E1E1E;
    font-weight: 700;
    line-height: 36px;
    font-size: 30px;
    margin-bottom: 28px;
}

.formControl {
    margin-bottom: 16px;
}

.passwordContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.passwordContainer span {
    font-size: 14px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #7D7D7D;
}

input {
    width: 100%;
    border-radius: 10px;
    gap: 10px;
    border: 1px solid #7D7D7D;
    padding: 16px 14px 16px 14px;
    font-size: 16px;
}

textarea {
    width: 100%;
    border-radius: 10px;
    gap: 10px;
    border: 1px solid #7D7D7D;
    padding: 16px 14px 16px 14px;
}

.error {
    color: red;
    font-size: 12px;
    text-align: left;
    padding-top: 4px;
}

.httpError {
    color: red;
    font-size: 14px;
    margin-bottom: 16px;
}

.link {
    height: 18px;
    font-size: 16px;
    color: #C8232C;
    margin-top: 24px;
    margin-bottom: 24px;
    display: block;
    cursor: pointer;
}

.step2Title {
    margin-bottom: 24px;
}

.step2Title span {
    color: #C8232C;
    margin-left: 4px;
}