.PortfolioSection {
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
}

h3 {
    color: #C8232C;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 50px;
}

.HorizontalScrollContainer {
    width: 100%; /* Set the width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
    margin-top: 24px;
}

/* Hide the scrollbar */
.HorizontalScrollContainer::-webkit-scrollbar {
    display: none; /* Hide scrollbar for webkit browsers (Chrome, Safari, etc.) */
}

.ScrollContent {
    display: inline-block; /* Make the content inside scrollable */
    white-space: nowrap; /* Prevent items from wrapping to the next line */
}

.WorkCard {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 24px;
    display: inline-block; /* Display items in a row */
}

.ImageContainer {
    width: var(--img-width);
    height: var(--img-height);
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 24px;
    position: relative;
    cursor: pointer;
}

.WatchButtonContainer {
    display: flex;
    visibility: hidden;
    border-radius: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
}

.WorkCard .watchButton {
    height: 120px;
    width: 120px;
    stroke-width: 1px;
    color: #D9D9D9;
    border: 1px solid #D9D9D9;
    z-index: 1;
    font-size: 16px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WorkCard img {
    width: var(--img-width);
    height: var(--img-height);
    border-radius: 16px;
}

.WorkDescription {
    display: flex;
    width: var(--img-width);
    justify-content: space-between;
    align-items: flex-start;
}

.WorkDescription .name {
    color: #1E1E1E;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.WorkDescription .squareMeters {
    color: #7D7D7D;
    text-align: right;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 480px) {

    .PortfolioSection {
        padding-top: 20px;
    }

    .ImageContainer {
        width: auto;
        height: 210px;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;
    }

    .WorkCard img {
        width: auto;
        height: 210px;
        border-radius: 16px;
    }

    .WorkDescription {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
    }

}
