.BusinessList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.CardsSection h2 {
    text-align: left;
    color: #7D7D7D;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
}

@media only screen and (min-width: 480px) {

    .Card {
        display: flex;
        align-items: flex-start;
        width: var(--img-width);
        height: 253px;
        background-color: #ffffff;
        border-radius: 16px;
        margin-top: 24px;
        padding: 20px;
        gap: 16px;
    }

    .CardsSection {
        padding-left: 50px;
        padding-right: 50px;
    }

    .Card .imageContainer {
        height: 213px;
        width: 290px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        background: #000;
    }
}

.Card .frame14 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.Card .frame10 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 0 0;
    align-self: stretch;
}

.Card .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.Card .name {
    color: #C8232C;
    font-family: Helvetica Neue, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.CardPrice {
    align-self: stretch;
    color: #1E1E1E;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.CardBio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.CardBio span {
    color: #1E1E1E;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Card .frame11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.ViewsCount {
    display: flex;
    align-items: flex-end;
    gap: 8px;
}

.ViewsCount span {
    color: #7D7D7D;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Card .moreButton {
    display: flex;
    height: 48px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid #C8232C;
    color: #C8232C;
    text-decoration: none;
    background-color: transparent;
    transition: all 0.3s ease-out;
    outline: none;
    cursor: pointer;
}

.Card h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.BusinessInformation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 0 0;
    align-self: stretch;
}

.BusinessInformationTop {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
}

.BusinessInformationBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}


.BusinessInformation h2 {
    font-family: Helvetica Neue, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #C8232C;
}

.BusinessInformation p {
    font-size: 0.88rem;
    font-weight: 300;
    line-height: 1rem;
    margin: 0;
}

.BusinessReviews h3 {
    margin: 0;
}

.BusinessAddress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PriceRange {
    color: #1E1E1E;
    font-family: Helvetica Neue, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.BusinessReviews {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
}

.BusinessReviews h3 {
    color: #cca353;
    font-weight: 600;
}

.BusinessReviews .rating {
    font-size: 0.88rem;
    line-height: 1rem;
}

@media only screen and (max-width: 480px) {

    .CardsSection {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
    }

    .CardsSection h2 {
        text-align: left;
        color: #7D7D7D;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
    }

    .Card {
        display: flex;
        align-items: flex-start;
        width: 100%;
        background-color: #ffffff;
        border-radius: 16px;
        margin-top: 24px;
        padding: 20px;
        gap: 16px;
        flex-direction: column;
    }

    .Card .imageContainer {
        height: auto;
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        background: #000;
    }

    .imageContainer img {
        width: 100%;
    }
}